$govuk-assets-path: "/static/govuk-frontend/govuk/assets/";
@import "./node_modules/govuk-frontend/govuk/all";

.idp {
  margin-top: 80px;
}

.available_idps li {
  line-height: 2em;
}

.available_idps li a {
    min-width: 200px;
    display: inline-block;
    text-align: center;
    margin-right: 20px;
}
